.App {
  text-align: center;
  background-color: #5a6a59;
  min-height: 100vh;         
}

body {
  margin: 0;
  padding: 0;
  background-color: #5a6a59;
}

.App-header {
  background-color: #282c34;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
  margin-bottom: 20px;
}

.card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  margin: 16px auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: box-shadow 0.3s ease-in-out;
  max-width: 600px;
  width: 90%;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.card-header {
  font-size: 1.0rem;
  margin: 0 0 16px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  color: #333;
}
